import "../scss/fundraiser.scss";

document.addEventListener("DOMContentLoaded", function () {
    // console.log("DOM fully loaded and parsed");

    const selector =
        '.fundraiser__mission--text .se-image-container img[data-rotate="270"], .fundraiser__mission--text .se-image-container img[data-rotate="90"], .fundraiser__mission--text .se-image-container img[data-rotate="-270"], .fundraiser__mission--text .se-image-container img[data-rotate="-90"]';

    const rightSelector =
        '.fundraiser__mission--text .se-image-container.__se__float-right img[data-rotate="270"], .fundraiser__mission--text .se-image-container.__se__float-right img[data-rotate="-90"]';

    document.querySelectorAll(selector).forEach((item) => {
        const computed = getComputedStyle(item);
        const styles: string = item.getAttribute("style") || "";

        item.setAttribute("style", `${styles}${styles ? ";" : ""}transform-origin: calc(${computed.width}/2)  calc(${computed.width}/2) 0px;`);
    });

    document.querySelectorAll(rightSelector).forEach((item) => {
        const computed = getComputedStyle(item);

        if (item.parentElement) {
            const styles: string = item.parentElement.getAttribute("style") || "";

            item.parentElement.setAttribute("style", `${styles}${styles ? ";" : ""}transform: translateX(calc(-${computed.width}/1.3));`);
        }
    });
});
